.order-details-container {
    width: 70rem;

    .order-details-header {
        font-size: $base-m-font-size;
        font-weight: 600;
        margin-bottom: 5rem;
        text-align: center;
    }

    .order-details,
    .other-information {
        margin-bottom: 4rem;

        .details-item {
            border-bottom: 1px $modal-background solid;
            display: flex;
            font-size: $base-ms-font-size;
            justify-content: space-between;
            padding: 1rem 0;

            .package-price {
                .price-amount {
                    font-weight: 600;
                    padding-right: 0.3rem;
                }
            }

            .insurance-cost {
                .price-amount {
                    padding-right: 0.3rem;
                }
            }
        }
    }

    .other-information {
        .other-information-title {
            border-bottom: 1px $modal-background solid;
            font-size: $base-ml-font-size;
            font-weight: 600;
            padding: 1rem 0;
        }

        .description {
            font-size: $base-ms-font-size;
        }
    }
}