.size-icon {
    align-items: center;
    border-radius: 1.2rem;
    display: flex;
    height: 3.2rem;
    justify-content: center;
    width: 3.2rem;

    .size-text {
        font-family: $inter-font-family;
        font-size: $base-m-font-size;
        font-weight: 700;
        line-height: 166%;
        text-transform: uppercase;

        &.text-s {
            color: $s-package-text-color;
        }

        &.text-m {
            color: $m-package-text-color;
        }

        &.text-l {
            color: $l-package-text-color;
        }
    }

    &.size-s {
        background-color: $s-package-background-color;
    }

    &.size-m {
        background-color: $m-package-background-color;
    }

    &.size-l {
        background-color: $l-package-background-color;
    }
}
