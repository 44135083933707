.location-operation-container {
    gap: 1.6rem;
    padding: 6.4rem;

    .location-operation-header {
        margin-bottom: 3.2rem;

        .header {
            font-family: $jakarta-font-family;
            font-size: $xxl-font-size;
            font-style: normal;
            font-weight: 700;
            line-height: 3.8rem;
        }
    }

    .map-container {
        height: 100%;
        width: 100%;

        .leaflet-container {
            height: 100%;
            width: 100%;
        }
    }

    .card {
        .card-footer {
            padding: 1.6rem 2.4rem;
        }
    }
}

.opening-hours-input-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;

    .header {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .title {
            color: $title-color;
            font-family: $inter-font-family;
            font-size: $base-m-font-size;
            font-weight: 500;
            line-height: 157%;
        }
    }

    .opening-hours-view-list {
        display: flex;
        flex-direction: column;
        padding: 1rem;

        .opening-hours-list-row {
            align-items: center;
            color: $title-color;
            display: flex;
            font-family: $inter-font-family;
            font-size: $small-base-font-size;
            font-style: normal;
            font-weight: 600;
            justify-content: space-between;
            letter-spacing: 0.02rem;
            line-height: 2.2rem;
            padding: 0.8rem;

            .opening-hours-list-value {
                color: $subtitle-text-color;
            }
        }
    }

    .opening-hours-form {
        .opening-hours-control-group {
            border-bottom: thin solid $light-text-color;
            display: flex;
            flex : 1;
            gap: 0.25rem;
            justify-content: space-between;
            padding: 1rem 0;

            &.group-with-error {
                background-color: $error-background-color;
                border-bottom: thin solid $error-color;
            }

            .group-label {
                align-items: center;
                color: $basic-text-color;
                display: flex;
                flex: 4;
                font-size: $base-font-size;
                width: unset;
            }

            .form-control-wrapper {
                flex: 1;
                margin: 0;
                padding: 0;

                .label-container {
                    display: none;
                }

                .input-container {
                    .input {
                        padding: 0.5rem;
                        width: 100%;
                    }
                }
            }
        }
    }
}


.opening-hours-modal-card {
    max-height: 90%;

    .opening-hours-card-content {
        overflow-y: auto;

        .day-control {
            border-bottom: 0.1rem solid $divider-color;
            padding: 0.8rem 0;

            .day-control-wrapper {
                align-items: center;
                height: 5.6rem;
                justify-content: space-between;
                padding: 0 1.6rem;

                .unavailable-day-label {
                    color: $subtitle-text-color;
                    font-family: $inter-font-family;
                    font-size: $small-base-font-size;
                    font-weight: 600;
                    letter-spacing: 0.02rem;
                    line-height: 2.2rem;
                }

                .day-label {
                    .MuiFormControlLabel-label {
                        color: $title-color;
                        font-family: $inter-font-family;
                        font-size: $small-base-font-size;
                        font-weight: 600;
                        letter-spacing: 0.02rem;
                        line-height: 2.2rem;
                    }
                }

                .MuiFormControl-root {
                    padding-bottom: 0;
                    width: 13rem;
                }
            }
        }
    }
}

.delete-location-card {
    max-width: unset;
    width: 100%;

    .title {
        color: $title-color;
        font-family: $jakarta-font-family;
        font-size: $m-font-size;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    .description {
        color: $title-color;
        font-family: $inter-font-family;
        font-size: $base-m-font-size;
        font-weight: 500;
        line-height: 157%;
        margin-bottom: 2.4rem;
    }

    &[class*="MuiCard-root"] {
        max-width: unset;
    }
}


.card-icon {
    height: 7.2rem;
    width: 7.2rem;
}


