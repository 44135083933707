.map-container {
    display: flex;
    height: 100%;
    min-height: 100%;

    .leaflet-container {
        min-width: 100%;

        .leaflet-map-pane {
            .disabled-polygon {
                color: $info-color;
                cursor: not-allowed;
                opacity: 0.5;
                pointer-events: none;
            }

            .leaflet-marker-pane {
                .leaflet-div-icon {
                    background-color: rgba($green, 0.4);
                    border-color: $input-border-color;
                    border-radius: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-medium) {
    .map-container {
        min-height: 50rem;
    }
}
