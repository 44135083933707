.package-size-preview {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    &.dark-mode {
        .package-size-label {
            background-color: rgba($theme-dark-border-color, 0.2);
        }
    }

    .package-size-label {
        align-items: center;
        background-color: $package-size-preview-label-background;
        border-radius: 0.4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0.2rem;
        padding-left: 0.6rem;
        padding-right: 0.6rem;
        position: absolute;

        &.height-label {
            right: 11rem;
            top: 6rem;
        }

        &.width-label {
            right: 13rem;
            top: 1rem;
        }

        &.weight-label {
            top: 3.5rem;
        }

        &.length-label {
            left: 12rem;
            top: 1rem;
        }

        .label {
            font-family: $inter-font-family;
            font-size: $xxs-font-size;
            font-weight: 500;
            line-height: 1.3rem;
            margin-right: 0.4rem;
        }
    }
}

.suggested-category-title {
    padding-bottom: 0.4rem;
    text-align: center;
}

.suggested-category-details {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1.6rem 0;
}

.modal-description {
    color: $subtitle-text-color;
    font-family: $inter-font-family;
    font-size: $base-font-size;
    font-weight: 500;
    line-height: 1.57rem;
    margin-bottom: 4rem;
    margin-top: 2rem;
    text-align: center;
}
