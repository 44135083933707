.settings-form-container{
    .switch-wrapper{
        display: flex;
        justify-content: space-between;
        margin-bottom: 5rem;

        .switch-label{
            color:$black-color;
            font-size: $base-m-font-size
        }
    }
}