.account-container {
    position: relative;
    width: 42.5rem;

    .card-body {
        padding: 0;

        .account-header {
            align-items: center;
            background-color: $datepicker-filter;
            display: flex;
            height: 24.5rem;
            justify-content: center;

            .account-header-title {
                font-size: $x-font-size;
                margin-bottom: 7rem;
            }
        }

        .account-image-container {
            border-radius: 50%;
            height: 18rem;
            position: absolute;
            right: 12.5rem;
            top: 11.2rem;
            width: 18rem;

            .image-wrapper {
                height: 100%;
                position: relative;
                width: 100%;

                .image {
                    background-color: $white-color;
                    border-radius: 50%;
                    height: 18rem;
                    object-fit: fill;
                    width: 18rem;
                }
            }

            .add-image-button {
                background: $white-color;
                background-image: url(../images/add-change-button-Icon.png);
                background-position: center;
                border-radius: 50%;
                bottom: -1.6rem;
                box-shadow: 0.1rem 0.2rem 1.3rem 0 $button-shadow;
                height: 4rem;
                position: absolute;
                right: 7rem;
                width: 4rem;
            }
        }

        .account-info {
            margin-bottom: 12rem;
            margin-top: 8rem;

            .account-info-container {
                margin-top: 2rem;

                .info-title-wrapper {
                    border-bottom: 1px $modal-background solid;
                    display: flex;
                    justify-content: space-between;

                    .info-title {
                        font-size: $ml-font-size;
                        padding: 0 0 0 2rem;
                    }

                    .edit-button {
                        background: none;
                        font-size: $base-font-size;
                        margin-right: 2rem;
                        padding-bottom: 1rem;
                        position: relative;
                    }

                    .edit-button::before {
                        bottom: 0.5rem;
                        content: url(../images/edit-icon.svg);
                        position: absolute;
                        right: 4.7rem
                    }
                }

                .info-list {
                    padding-right: 2rem;

                    .info-item {
                        border-bottom: 1px $modal-background solid;
                        display: flex;
                        justify-content: space-between;
                        list-style: none;

                        .text {
                            font-size: $base-font-size;
                            padding: 1rem 0 1rem 1rem;
                        }
                    }
                }
            }
        }
    }
}