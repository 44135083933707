.locations-container {
    height: 100%;
    padding: 6.4rem;
    width: 100%;

    .locations-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6.3rem;

        .title {
            font-size: $xxl-font-size;
        }
    }

    .action-cell {
        display: flex;
        justify-content: flex-end;
        min-height: 8rem;
        padding-right: 0;

        .action-button {
            color: $black;
            padding-right: 0;

            .button-text {
                color: $black;
                font-size: $small-base-font-size;
                font-weight: 600;
            }
        }
    }
}
