.job-create-view {
    .insurance-control {
        margin: 1.6rem 0;

        .slider-control-container {
            align-items: center;
            display: flex;
            margin-bottom: 1.6rem;

            .slider-control {
                flex: 2;
                margin-right: 1.6rem;
                position: relative;
            }

            .slider-input {
                flex: 1;
            }

            .slider-label {
                display: flex;
                flex: 1;
                justify-content: space-between;
                position: absolute;
                top: 3rem;
                width: 100%;

                .value-container {
                    display: flex;

                    .label {
                        font-family: $inter-font-family;
                        font-size: $xxs-font-size;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.4rem;
                    }
                }
            }
        }
    }

    .map-container {
        height: auto;
        padding: 2.4rem 2.4rem 2.4rem 0.8rem;
        padding-left: 0.8rem;
        width: 100%;

        .leaflet-container {
            height: 100%;
            min-height: 29.4rem;
            width: 100%;
        }
    }

    .navigation-buttons-container {
        padding: 1.6rem 2.4rem;

        .next-button {
            color: $white-color;
        }
    }

    .completion-control {
        gap: 0;
        padding-top: 1.6rem;

        .job-create-general-data-form-immediate-completion-switch {
            justify-content: flex-start;
        }
    }

    .sender-data-container {
        .sender-data-wrapper {
            padding: 2.4rem 0.8rem 2.4rem 2.4rem;

            .summary-details-wrapper {
                margin-bottom: 1.6rem;
            }
        }

        .map-container {
            height: auto;
            padding: 2.4rem 2.4rem 2.4rem 0.8rem;
            padding-left: 0.8rem;
            width: 100%;

            .leaflet-container {
                height: 100%;
                min-height: 29.4rem;
                width: 100%;
            }
        }

        .sender-data-description {
            color: $subtitle-text-color;
            font-weight: 400;
            margin-bottom: 1.6rem;

            &.sender-phone-description {
                font-size: $small-font-size;
                margin-bottom: 2rem;
            }
        }

        .add-location-button {
            align-self: flex-start;
            color: $title-color;
            justify-content: flex-start;
            padding: 0.2rem 0.8rem 0.2rem 0;
            width: auto;
        }

        // .MuiFormControl-root {
        //     padding-bottom: 1.6rem;
        // }

        .address-book-control {
            align-items: center;
            align-self: flex-start;
            background-color: $icon-bg;
            border-radius: 0.8rem;
            display: flex;
            height: 3.6rem;
            justify-content: center;
            margin-left: 1.6rem;
            margin-top: 1rem;
            width: 3.6rem;

            &:hover {
                opacity: 0.4;
            }
        }

        .recipient-label {
            margin-bottom: 2.4rem;
        }
    }

    .create-job-recipient-heading-wrapper {
        padding: 2.4rem 2.4rem 0;
    }

    .create-job-recipient-data-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        padding: 1.6rem 2.4rem 2.4rem;

        .create-job-recipient-data-form {
            .form-controls-wrapper {
                .create-job-recipient-data-provide-switch {
                    flex-direction: row;
                    justify-content: flex-start;
                    padding-bottom: 1.6rem;

                    .MuiSwitch-switchBase {
                        &:not(.Mui-checked) {
                            color: $gray-50;
                            filter: drop-shadow(0 0.1rem 0.2rem rgba($black-color, 0.08));
                        }
                    }
                }
            }
        }
    }

    .summary-details {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0.8rem 0;

        &.dark-mode {
            .summary-label, .summary-value {
                &.disabled {
                    color: rgba($white-color, 0.2);
                }
            }
        }

        .summary-label {
            font-family: $inter-font-family;
            font-size: $small-base-font-size;
            font-weight: 600;
            letter-spacing: 0.02rem;
            line-height: 2.2rem;

            &.disabled {
                color: rgba($title-color, 0.2);
            }
        }

        .summary-value {
            font-family: $inter-font-family;
            font-size: $small-base-font-size;
            font-weight: 600;
            letter-spacing: 0.02rem;
            line-height: 2.2rem;

            &.disabled {
                color: rgba($title-color, 0.2);
            }
        }
    }

    .credit-card-details {
        .no-couriers-box {
            align-self: flex-start;
            border-radius: 2rem;
            display: flex;
            padding: 1.6rem;

            .no-couriers-text {
                opacity: 0.9;
            }
        }

        .payment-details {
            align-items: flex-start;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &.disabled {
                opacity: 0.2;
            }

            .card-details {
                align-items: center;
                display: flex;
                flex: 2;
                gap: 0.8rem;
                padding: 0.8rem;
            }

            .job-price {
                display: flex;
                flex: 2;
                justify-content: flex-end;
                padding: 0.8rem 0;

                .price-values,
                .price-labels {
                    align-items: flex-end;
                    display: flex;
                    flex-direction: column;

                    p {
                        letter-spacing: 0.02rem;
                        line-height: 1.8rem;
                        margin-bottom: 0.3rem;
                    }

                    .secondary-value {
                        color: $subtitle-text-color;
                        font-size: $xxs-font-size;
                        font-weight: 500;
                    }
                }

                .price-labels {
                    margin-right: 0.8rem;
                }
            }
        }
    }

    .new-job-card {
        .loader-container {
            &.local {
                z-index: 1001;
            }
        }
    }
}

.box-dimensions-modal {
    .custom-modal-card {
        max-width: 50rem;
    }
}

.address-book-modal {
    .MuiPaper-root.MuiPaper-elevation {
        align-items: stretch;
        border-radius: 1.6rem;
        display: flex;
        flex-direction: column;
        height: 72rem;
        justify-content: center;
        width: 54rem;

        .MuiCardContent-root {
            align-items: stretch;
            height: 100%;
            justify-content: flex-start;
            overflow-y: auto;

            .address-book-modal-content-wrapper {
                gap: 1.6rem;

                .team-name {
                    color: $title-color;
                    font-family: $inter-font-family;
                    font-size: $base-m-font-size;
                    font-weight: 500;
                    line-height: 2.5rem;
                    text-transform: uppercase;
                }

                .tab-controls {
                    button {
                        flex: 1;
                    }
                }

                .location-search-wrapper {
                    .location-filters-wrapper {
                        gap: 1.6rem;
                    }
                }

                .tabs-panel {
                    .location-list-container {
                        max-height: 40rem;
                        overflow-y: auto;

                        .address-book-item {
                            font-family: $inter-font-family;
                            padding: 0.4rem 0.8rem 0.4rem 1.6rem;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}
