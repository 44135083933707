.package-size-container {
    align-items: center;
    background-color: $s-package-background-color;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    padding: 1.6rem 0.8rem;
    width: 12rem;

    .package-icon {
        align-items: center;
        border: 0.1rem solid $s-package-text-color;
        border-radius: 50%;
        color: $s-package-text-color;
        display: flex;
        font-weight: 700;
        height: 2.4rem;
        justify-content: center;
        margin-bottom: 0.8rem;
        width: 2.4rem;

        &.package-icon-m {
            border-color: $m-package-text-color;
            color: $m-package-text-color;
        }

        &.package-icon-l {
            border-color: $l-package-text-color;
            color: $l-package-text-color;
        }

        .bold {
            font-weight: 700;
        }
    }

    .box-size-details {
        color: $s-package-text-color;
        font-family: $inter-font-family;
        font-size: $small-font-size;
        font-weight: 400;
        letter-spacing: 0.04rem;
        line-height: 2rem;
        text-align: center;

        .box-size-details-m {
            color: $m-package-text-color;
        }

        .box-size-details-l {
            color: $l-package-text-color;
        }
    }

    &.package-size-container-m {
        background-color: $m-package-background-color;
    }

    &.package-size-container-l {
        background-color: $l-package-background-color;
    }
}
