.delivery,
.cancelled,
.searching,
.planned,
.reported {
    border-radius: 0.8rem;
    font-size: $small-font-size;
    font-weight: 500;
    padding: 0.2rem 0.8rem;
}

.package-status-badge {
    .delivery {
        background-color: $completed-bg ;
        color: $completed;
    }

    .reported,.planned {
        background-color: $reported-bg ;
        color: $reported;
    }

    .cancelled {
        background-color: $canceled-bg ;
        color: $canceled;
    }

    .searching {
        background-color: $success-darkest-background;
        color: $success-darkest;
    }
}

.package-price,
.package-price-color {
    font-size: $base-font-size;
    font-weight: 500;

    .price-amount {
        padding-right: 0.3rem;
    }
}

.bold-text {
    font-weight: 700;
}

.report-list {
    align-items: flex-start;

    .report-item {
        cursor: pointer;
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        margin-bottom: 1.6rem;
        padding: 0.8rem;
        width: 100%;
    }

}

.team-loading-card {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 75vh;
}

.flex-centered-item {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}