.courier-details-container {
    width: 70rem;

    .courier-details-header,
    .rating-section {
        align-items: center;
        display: flex;
        flex-direction: column;

        .courier-name {
            font-size: $base-m-font-size;
            font-weight: 600;
            margin-bottom: 5rem;
        }
    }

    .courier-details {
        margin-bottom: 4rem;

        .details-item {
            border-bottom: 1px $modal-background solid;
            display: flex;
            font-size: $base-ms-font-size;
            justify-content: space-between;
            padding: 1rem 0;
        }
    }

    .rating-section {
        .stars,
        .courier-rating {
            font-size: $xxxxml-font-size;
        }
    }

    .button-wrapper {
        margin-top: 2rem;

        .confirm-button {
            border-radius: 2.6rem;
            font-size: $base-m-font-size;
            padding: 1rem 0;
            width: 100%;
        }
    }
}
