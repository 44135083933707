.job-view-wrapper {
    max-width: 100vw;

    &.MuiCard-root {
        border-radius: 0;
    }

    .job-view {
        display: flex;
        flex-direction: row;
        font-family: $inter-font-family;
        height: 100vh;
        overflow: hidden;
        padding: 0;

        .map-container {
            flex: 3;

            .leaflet-container {
                height: 100vh;
                margin-top: 0;
                width: 100%;
            }

            .refresh-position-button {
                position: absolute;
                right: 5rem;
                top: 5rem;
                z-index: 1000;
            }
        }

        .actions-container {
            padding: 1.6rem;

            .invoice-button {
                margin-top: 2.6rem;
                width: 100%;
            }

            .renew-order-button {
                margin-top: 1.6rem;
                width: 100%;
            }
        }
    }
}

.cancel-job-modal-content {
    align-items: center;
    display: flex;
    gap: 1.6rem;

    .cancel-job-modal-description-wrapper {
        align-items: center;
        gap: 1.6rem;
        text-align: center;

        .bold-text {
            font-weight: 700;
        }
    }
}

.courier-account-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .image-wrapper {
        border-radius: 1.6rem;
        height: 10rem;
        width: 10rem;

        .image {
            border-radius: 25%;
            height: inherit;
            object-fit: contain;
            width: inherit;
        }
    }
}

.courier-details-modal {
    .custom-modal-content {
        padding: 2.4rem 0;
    }
}

.report-issue-modal {
    .custom-modal-content {
        padding: 2.4rem 2.4rem 0;

        .report-problem-form-wrapper {
            gap: 1.6rem;

            .report-problem-header {
                border-bottom: 1px solid $divider-color;
                padding: 0.4rem 1.6rem;
            }
        }
    }
}

.rating-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span[class*="disabled"] {
        opacity: 1;
    }

    .rating-value {
        color: $title-color;
        font-family: $jakarta-font-family;
        font-size: $x-font-size;
        font-weight: 700;
        line-height: 2.88rem;
        margin-top: 1rem;
    }
}
