@import url("~leaflet/dist/leaflet.css");
@import url("~leaflet-draw/dist/leaflet.draw.css");
@import "~palipali-panel-styles/normalize";
@import "~palipali-panel-styles/panel/variables";
@import "~palipali-panel-styles/panel/style";
@import "./teams.scss";
@import "./edit.scss";
@import "./locations.scss";
@import "./account.scss";
@import "./settings.scss";
@import "./jobView.scss";
@import './leaflet.scss';
@import './courierDetails.scss';
@import './orderDetails.scss';
@import './sharedComponents.scss';
@import './createJobView.scss';
@import './package-size-modal';
@import './boxSizeIcon';
@import './boxSizeItem';
@import './locationOperation.scss';

.sr-only {
    border: 0 !important;
    clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 0.1rem !important;
    margin: -0.1rem !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 0.1rem !important;
}

.horizontal-spacer {
    margin: 0 1rem;
}

p {
    margin-bottom: 0;
}

body {
    font-family: $inter-font-family;
    font-family: $jakarta-font-family;
}
