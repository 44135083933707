.settings-form-container{
    max-width: 60rem;

    .section-title, .button-wrapper{
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .settings-form-wrapper{
        margin-bottom: 7rem;
    }

    .link-wrapper{
        display: flex;
        flex-direction: column;
        font-size: $base-m-font-size;

        .link{
            border-top: 1px $modal-background solid;
            color: $black-color;
            padding: 1rem 0;
            position: relative;
        }

        .link::after{
            content: url(../images/arrow-forward.svg);
            position: absolute;
            right: 2.5rem;
        }

        .link:hover::after{
            content: url(../images/red-arrow-forward.svg);
        }

        .link:last-child{
            border-bottom: 1px $modal-background solid;
        }

        .link:hover, .link:active{
            color: $danger-color;
        }
    }

    .button-wrapper{
        margin-top: 2rem;

        .confirm-button{
            border-radius: 2.6rem;
            font-size: $base-m-font-size;
            padding: 1rem 0;
            width: 100%;
        }
    }
}