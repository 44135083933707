.team-details-container {
    display: flex;
    flex-direction: column;

    .team-details-title {
        font-size: $m-font-size;
        padding-top: 1.6rem;

        .title-text {
            font-weight: 700;
        }
    }

    .form-container {
        .form {
            width: 100%;
        }

        .form-container-subtitle {
            border-bottom: 0.1rem solid $icon-bg;
            color: $black;
            margin-bottom: 2.4rem;
            margin-top: 2.4rem;
            padding: 0.4rem;
        }
    }

    .submit-button {
        align-self: flex-end;
        background: inherit;
        height: 2rem;
        margin-left: 1.25rem;
        outline: inherit;
        padding-bottom: 3rem;

        .button-text {
            color: $black;
            font-size: $base-font-size;
            text-transform: initial;
        }
    }

    .create-button {
        font-size: $base-font-size;
        height: 3.6rem;
        max-width: 13rem;
        text-transform: none;
    }
}

.custom-modal-wrapper {
    &.team-member-edit-modal {
        .custom-modal-content {
            align-items: stretch;
            padding: 0 0 2.4rem;

            .team-member-role-select {
                padding: 0 2.4rem;
            }
        }
    }
}

.team-member-badge {
    border-radius: 0.5rem;
    font-size: $small-font-size;
    font-weight: 600;
    line-height: 1;
    padding: 0.5rem 1rem;

    &.ROLE_PURCHASER_TEAM_OWNER {
        background-color: $owner-badge-background-color !important;
        color: $owner-badge-color;
    }

    &.ROLE_PURCHASER_TEAM_MANAGER {
        background-color: $manager-badge-background-color !important;
        color: $manager-badge-color;
    }

    &.ROLE_PURCHASER_TEAM_EMPLOYEE {
        background-color: $employee-badge-background-color !important;
        color: $employee-badge-color;
    }

    &.invited {
        background-color: $invited-badge-background-color !important;
        color: $invited-badge-color;
    }
}

.team-verified-badge {
    background-color: $unverified-badge-background-color !important;
    border-radius: 0.5rem;
    color: $unverified-badge-color;
    font-size: $small-font-size;
    font-weight: 600;
    margin-left: 3rem;
    padding: 0.5rem 1rem;
}

.payment-method-cart {
    .payment-provider-info {
        align-items: center;
        display: flex;

        .stripe-icon {
            margin-left: 2rem;
            width: 12rem;
        }
    }

    .card-preview-wrapper {
        .card-preview {
            border: 1px solid $icon-bg;
            border-radius: 0.4rem;
            display: flex;
            margin-bottom: 2.4rem;
            max-width: 53rem;
            padding: 0.7rem 1.4rem;
            width: 100%;

            .card-preview-icon {
                margin-right: 1.2rem;
                width: 3rem;
            }
        }
    }

    .card-element {
        border: 1px solid $icon-bg;
        border-radius: 0.4rem;
        box-shadow: 0 0.1rem 0.3rem 0 $card-box-shadow-color;
        max-width: 53rem;
        padding: 1rem 1.2rem;
        width: 100%;
    }
}

.add-payment-method-button {
    align-self: flex-start;
    border-color: $theme-color;
    color: $theme-color;
    flex: 0 0 auto;
    width: auto;

    &:hover {
        background-color: rgba($theme-color, 0.04);
    }
}

.delete-payment-method-button {
    align-self: flex-start;
    flex: 0 0 auto;
    width: auto;
}

.action-button-wrapper {
    display: flex;
    position: relative;

    .action-popup-wrapper {
        &.member {
            .action-popup {
                left: unset;
                right: 0;
            }
        }

        .popup-backdrop {
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: 9;
        }

        .action-popup {
            align-items: flex-start;
            background-color: $white-color;
            border-radius: 0.6rem;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
            display: flex;
            flex-direction: column;
            gap: 1rem;
            left: 0;
            padding: 2rem;
            position: absolute;
            top: 2rem;
            white-space: nowrap;
            z-index: 20;

            .action-popup-button-wrapper {
                display: flex;
                gap: 1rem;
                justify-content: flex-end;
                width: 100%;
            }

            .popup-header {
                align-items: center;
                display: flex;
                flex: 1;
                justify-content: space-between;
                width: 100%;

                button {
                    color: $black-color;
                    font-weight: 500;
                }
            }

            .popup-body {
                border-bottom: thin solid rgba($black-color, 0.05);
                border-top: thin solid rgba($black-color, 0.05);
                padding: 3.5rem 0 3rem;

                .warning-wrapper {
                    background-color: rgba($error-color, 0.12);
                    border: 1px solid $error-color;
                    border-radius: 0.6rem;
                    color: $error-color;
                    font-size: $base-font-size;
                    margin-bottom: 2rem;
                    padding: 0.8rem 1rem;

                    svg {
                        margin-right: 2rem;
                    }
                }

                .edit-form-control-subtitle {
                    color: $black-color;
                    font-size: $base-font-size;
                }
            }
        }
    }
}

.table-pagination {
    .MuiToolbar-gutters {
        .MuiTablePagination-selectLabel {
            display: none;
        }
    }

    .css-1nf6ioj-MuiInputBase-root-MuiTablePagination-select {
        display: none;
    }
}
